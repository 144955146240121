.App {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	min-height: 100vh;
	background-color: #f0f0f0;
}

.container {
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: column;
}
